import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
// import Tables from "../views/Tables.vue";
// import Billing from "../views/Billing.vue";
// import RTL from "../views/Rtl.vue";
// import Notifications from "../views/Notifications.vue";
// import Profile from "../views/Profile.vue";
// import Marketplace from "../views/Marketplace.vue";
// import NftDetails from "../components/NftDetails";
// import Collection from "../views/Collection";
// import Calculator from "@/views/Calculator";
// import Mintable from "@/views/Mintable";
// import NftWorldsPlayerStats from "@/views/NftWorldsPlayerStats";
// import Staking from "@/views/Staking";
// import Swap from "@/views/Swap";
// import SignIn from "../views/SignIn.vue";
// import SignUp from "../views/SignUp.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  // },
  // {
  //   path: "/nft-view",
  //   name: "NftDetails",
  //   component: NftDetails,
  // },
  // {
  //   path: "/marketplace",
  //   name: "Marketplace",
  //   component: Marketplace,
  // },
  // {
  //   path: "/collection",
  //   name: "Collection",
  //   component: Collection,
  // },
  // {
  //   path: "/kickback-calc",
  //   name: "Kickback Calculator",
  //   component: Calculator,
  // },
  // {
  //   path: "/mintable",
  //   name: "Mintable",
  //   component: Mintable,
  // },
  // {
  //   path: "/nftworlds-players-stats",
  //   name: "NftWorlds Players",
  //   component: NftWorldsPlayerStats,
  // },
  // {
  //   path: "/staking",
  //   name: "Staking",
  //   component: Staking,
  // },
  // {
  //   path: "/swap",
  //   name: "Swap",
  //   component: Swap,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
