<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-md-12 col-12 mb-4 text-center">
        <img src="../assets/img/logoOne.png" style="width: 25%" />
      </div>
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Gold Upgrades Available"
              v-bind:value="
                this.completedGoldUpgrades === 0
                  ? 'N/A'
                  : 450 - this.completedGoldUpgrades
              "
              iconName="upgrade"
              iconClass="text-white"
              iconBackground="bg-gradient-warning"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Royal Upgrades Available"
              v-bind:value="
                this.completedRoyalUpgrades === 0
                  ? 'N/A'
                  : 30 - this.completedRoyalUpgrades
              "
              iconName="upgrade"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Central Passes Held"
              v-bind:value="this.centralNfts.length"
              iconName="local_activity"
              iconClass="text-white"
              iconBackground="bg-gradient-dark"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Gold Passes Held"
              v-bind:value="this.goldNfts.length"
              iconName="local_activity"
              iconClass="text-white"
              iconBackground="bg-gradient-dark"
            />
          </div>
        </div>
        <!--Start Upgrade mode selection-->
        <div class="row mt-4" v-if="upgradeMode == null">
          <div class="col-md-3 col-12 mb-4"></div>
          <div class="col-md-6 col-12 mb-4 text-center mt-5">
            <h4>Upgrades Sold Out!</h4>
            <p>
              Thank you everyone for participating. Stay tuned for the next
              event!
            </p>
            <button
              type="button"
              class="btn btn-lg btn-warning mx-2"
              v-on:click="setUpgradeMode('Gold')"
              disabled
            >
              Upgrade to Gold
            </button>
            <button
              type="button"
              class="btn btn-lg btn-primary"
              v-on:click="setUpgradeMode('Royal')"
              disabled
            >
              Upgrade to Royal
            </button>
          </div>
          <div class="col-md-3 col-12 mb-4"></div>
        </div>
        <!--End Upgrade mode selection-->
        <!--Start Upgrade View-->
        <div class="row mt-4" v-else>
          <div class="col-md-12 col-12 mb-4 text-center mt-5">
            <!--Genric Info View-->
            <h4>
              Upgrading
              <strong class="text-warning">{{
                this.upgradeMode === "Gold" ? "Centrals" : "Golds"
              }}</strong>
              to
              <strong class="text-primary">{{
                this.upgradeMode === "Gold" ? "Gold" : "Royal"
              }}</strong
              >:
            </h4>
            <p class="text-sm lead">
              Select
              <strong class="text-warning">{{
                this.upgradeMode === "Gold" ? "3" : "10"
              }}</strong>
              passes for upgrade. During Upgrade, selected passes will be
              burned.
            </p>
            <!--End Genric Info View-->
            <div class="row">
              <div class="col-md-12 col-12 text-center mb-4">
                <button
                  type="button"
                  class="btn btn-sm btn-dark mx-2"
                  v-on:click="setUpgradeMode(null)"
                  v-if="!inProgress"
                >
                  Back
                </button>
                <template v-if="inProgress">
                  <template v-if="details.status === 'In-Progress'">
                    <div class="spinner-border text-info mt-5" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <h6 class="text-info">
                      UPGRADE IN PROGRESS - WAIT PATIENTLY...
                    </h6>
                  </template>
                  <template v-if="details.status === 'Success'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="text-success"
                      class="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      />
                    </svg>
                    <h6 class="text-success">COMPLETE</h6>
                    <p class="mb-0 lead">
                      <strong>{{ details.message }}</strong>
                    </p>
                    <p class="mb-0 text-sm">
                      TxHash:
                      <strong>{{ details.transactionHash }}</strong>
                    </p>
                    <a
                      :href="details.etherscanLink"
                      target="_blank"
                      rel="noopener"
                      class="mb-0 text-info"
                    >
                      Link To Etherscan
                    </a>
                    <br />
                    <button
                      type="button"
                      class="btn btn-sm btn-dark mt-2 mx-2"
                      v-on:click="anotherUpgrade"
                    >
                      Upgrade Again
                    </button>
                  </template>
                  <template v-if="details.status === 'Error'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="text-danger"
                      class="bi bi-exclamation-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                      />
                    </svg>
                    <h6 class="text-danger">ERROR</h6>
                    <p class="mb-0 lead text-danger">
                      <strong>{{ details.message }}</strong>
                    </p>
                    <button
                      type="button"
                      class="btn btn-sm btn-dark mt-2 mx-2"
                      v-on:click="anotherUpgrade"
                    >
                      Try Again
                    </button>
                  </template>
                </template>
                <template v-else>
                  <!--Gold Upgrade Button View-->
                  <template v-if="upgradeMode === 'Gold'">
                    <button
                      type="button"
                      class="btn btn-lg btn-warning"
                      :disabled="
                        this.selectedForGoldUpgrade.length === 3 ? false : true
                      "
                      v-on:click="upgrade(this.selectedForGoldUpgrade, 'gold')"
                    >
                      Upgrade to Gold
                    </button>
                    <p class="lead text-sm">
                      Passes Selected for upgrade:
                      <strong class="text-warning">{{
                        selectedForGoldUpgrade
                      }}</strong>
                    </p>
                  </template>
                  <!--End Gold Upgrade Button View-->
                  <!--Royal Upgrade Button View-->
                  <template v-else>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      :disabled="
                        this.selectedForRoyalUpgrade.length === 10
                          ? false
                          : true
                      "
                      v-on:click="
                        upgrade(this.selectedForRoyalUpgrade, 'royal')
                      "
                    >
                      Upgrade to Royal
                    </button>
                    <p class="lead text-sm">
                      Passes Selected for upgrade:
                      <strong class="text-warning">{{
                        selectedForRoyalUpgrade
                      }}</strong>
                    </p>
                  </template>
                  <!--End Royal Upgrade Button View-->
                </template>
              </div>
              <template v-if="!inProgress">
                <!--Gold selection View-->
                <template v-if="upgradeMode === 'Gold'">
                  <template
                    v-for="central in sortedCentralNfts"
                    v-bind:key="central"
                  >
                    <div class="col-md-2 col-6 text-center mb-4">
                      <div class="card bg-success-soft">
                        <img
                          src="../assets/img/central.png"
                          class="card-img-top"
                          alt="central pass"
                        />
                        <div class="card-body">
                          <h5 class="card-title text-md">{{ central.name }}</h5>
                          <template
                            v-if="
                              selectedForGoldUpgrade.includes(central.tokenId)
                            "
                          >
                            <p class="lead text-md text-strong text-primary">
                              Pass is selected for upgrade
                            </p>
                          </template>
                          <template v-else>
                            <button
                              type="button"
                              class="btn btn-sm btn-primary"
                              :disabled="
                                this.selectedForGoldUpgrade.length === 3
                              "
                              v-on:click="
                                addSelectedForUpgrade('Gold', central.tokenId)
                              "
                            >
                              Select
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <!--End Gold selection View-->
                <!--Royal selection View-->
                <template v-else>
                  <template v-for="gold in sortedGoldNfts" v-bind:key="gold">
                    <div class="col-md-2 col-6 text-center mb-4">
                      <div class="card bg-success-soft">
                        <img
                          src="../assets/img/gold.png"
                          class="card-img-top"
                          alt="gold pass"
                        />
                        <div class="card-body">
                          <h5 class="card-title text-md">{{ gold.name }}</h5>
                          <template
                            v-if="
                              selectedForRoyalUpgrade.includes(gold.tokenId)
                            "
                          >
                            <p class="lead text-md text-strong text-primary">
                              Pass is selected for upgrade
                            </p>
                          </template>
                          <template v-else>
                            <button
                              type="button"
                              class="btn btn-sm btn-primary"
                              :disabled="
                                this.selectedForRoyalUpgrade.length === 10
                              "
                              v-on:click="
                                addSelectedForUpgrade('Royal', gold.tokenId)
                              "
                            >
                              Select
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <!--End Gold selection View-->
              </template>
            </div>
          </div>
        </div>
        <!--Start Upgrade View-->
      </div>
    </div>
  </div>
</template>
<script>
import MiniCards from "./components/MiniCards.vue";
import Moralis from "moralis";

export default {
  name: "dashboard-default",
  components: {
    MiniCards,
  },
  data() {
    return {
      centralNfts: [],
      goldNfts: [],
      upgradeMode: null,
      selectedForGoldUpgrade: [],
      selectedForRoyalUpgrade: [],
      keepFetcherAlive: true,
      lastCursor: null,
      upgradeContractAddress: "0xBA24BBF3616aBAEDC4cC6ca5400123A962cF0cA3".toLowerCase(),
      upgradeContractAddressAbi: [
        {
          inputs: [
            { internalType: "address", name: "cm_address", type: "address" },
            {
              internalType: "uint256[]",
              name: "_goldTokens",
              type: "uint256[]",
            },
            {
              internalType: "uint256[]",
              name: "_royalTokens",
              type: "uint256[]",
            },
            { internalType: "address", name: "_signer", type: "address" },
            { internalType: "address", name: "_prox", type: "address" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [
            { internalType: "uint256[]", name: "tokens", type: "uint256[]" },
          ],
          name: "addGoldTokens",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256[]", name: "tokens", type: "uint256[]" },
          ],
          name: "addRoyalTokens",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_signer", type: "address" },
          ],
          name: "changeSigner",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "cm",
          outputs: [
            { internalType: "contract ERC721", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "goldCost",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "goldIndex",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "goldPaused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          name: "goldTokens",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256[]", name: "tokenIds", type: "uint256[]" },
            { internalType: "bytes", name: "sig", type: "bytes" },
          ],
          name: "goldUpgrade",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "address", name: "", type: "address" },
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "bytes", name: "", type: "bytes" },
          ],
          name: "onERC721Received",
          outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "royalCost",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "royalIndex",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "royalPaused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          name: "royalTokens",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256[]", name: "tokenIds", type: "uint256[]" },
            { internalType: "bytes", name: "sig", type: "bytes" },
          ],
          name: "royalUpgrade",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [{ internalType: "bool", name: "paused", type: "bool" }],
          name: "setGoldPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "bool", name: "paused", type: "bool" }],
          name: "setRoyalPause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "wallet", type: "address" },
          ],
          name: "setWithdawWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "cost", type: "uint256" }],
          name: "updateGoldCost",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "cost", type: "uint256" }],
          name: "updateRoyalCost",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdraw",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      collectingMetaAddress: "0x1fF8fE884DB9124cC47405Fd2859dE1A8d9cE4e4".toLowerCase(),
      collectingMetaAddressAbi: [
        { inputs: [], stateMutability: "nonpayable", type: "constructor" },
        {
          inputs: [],
          name: "ApprovalCallerNotOwnerNorApproved",
          type: "error",
        },
        { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
        { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
        { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
        { inputs: [], name: "MintToZeroAddress", type: "error" },
        { inputs: [], name: "MintZeroQuantity", type: "error" },
        { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
        {
          inputs: [],
          name: "OwnershipNotInitializedForExtraData",
          type: "error",
        },
        {
          inputs: [],
          name: "TransferCallerNotOwnerNorApproved",
          type: "error",
        },
        { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
        {
          inputs: [],
          name: "TransferToNonERC721ReceiverImplementer",
          type: "error",
        },
        { inputs: [], name: "TransferToZeroAddress", type: "error" },
        { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "uint256",
              name: "fromTokenId",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "toTokenId",
              type: "uint256",
            },
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
          ],
          name: "ConsecutiveTransfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [],
          name: "airDropMerkleRoot",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "", type: "address" }],
          name: "airDropSaleClaimed",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "", type: "address" }],
          name: "airDropSaleClaimedChecker",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_addresses",
              type: "address[]",
            },
            { internalType: "uint256[]", name: "_amount", type: "uint256[]" },
          ],
          name: "airDropToList",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "owner", type: "address" }],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "baseExtension",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "baseURI",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32[]",
              name: "_merkleProof",
              type: "bytes32[]",
            },
            { internalType: "uint256", name: "tokens", type: "uint256" },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "claimedAmount",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "costOne",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "costThree",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "costTwo",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "currentCost",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "getApproved",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "getCurrentCost",
          outputs: [
            { internalType: "uint256", name: "_currentCost", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "_user", type: "address" }],
          name: "getPassBalance",
          outputs: [
            { internalType: "uint256", name: "_passBalance", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "operator", type: "address" },
          ],
          name: "isApprovedForAll",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "isClaimLive",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "maxPerTx",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "maxSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokens", type: "uint256" },
          ],
          name: "mint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "owner", type: "address" }],
          name: "numberMinted",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "ownerOf",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokens", type: "uint256" },
          ],
          name: "passHolderMint",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "bytes", name: "_data", type: "bytes" },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "merkle_root", type: "bytes32" },
          ],
          name: "setAirDropMerkleRoot",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "string",
              name: "_newBaseExtension",
              type: "string",
            },
          ],
          name: "setBaseExtension",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "string", name: "_newBaseURI", type: "string" },
          ],
          name: "setBaseURI",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newCost", type: "uint256" },
          ],
          name: "setCostOne",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newCost", type: "uint256" },
          ],
          name: "setCostThree",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newCost", type: "uint256" },
          ],
          name: "setCostTwo",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
          name: "setIsClaimLive",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newMaxSupply", type: "uint256" },
          ],
          name: "setMaxSupply",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newTokenTier", type: "uint256" },
          ],
          name: "setTokenTierOne",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_newTokenTier", type: "uint256" },
          ],
          name: "setTokenTierTwo",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "community", type: "address" },
            { internalType: "address", name: "teamOne", type: "address" },
            { internalType: "address", name: "teamTwo", type: "address" },
          ],
          name: "setWithdrawWallets",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes4", name: "interfaceId", type: "bytes4" },
          ],
          name: "supportsInterface",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "tokenTierOne",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "tokenTierTwo",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "tokenURI",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawFallback",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawMoney",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      network: "0x1",
      inProgress: false,
      details: {
        status: "In-Progress",
        message: "",
        transactionHash: "",
        etherscanLink: "",
      },
      completedGoldUpgrades: 0,
      completedRoyalUpgrades: 0,
    };
  },
  computed: {
    sortedGoldNfts: function () {
      function compare(a, b) {
        if (a.tokenId < b.tokenId) return 1;
        if (a.tokenId > b.tokenId) return -1;
        return 0;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.goldNfts.sort(compare);
    },
    sortedCentralNfts: function () {
      function compare(a, b) {
        if (a.tokenId < b.tokenId) return 1;
        if (a.tokenId > b.tokenId) return -1;
        return 0;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.centralNfts.sort(compare);
    },
  },
  methods: {
    setUpgradeMode(type) {
      this.selectedForGoldUpgrade = [];
      this.selectedForRoyalUpgrade = [];
      this.upgradeMode = type;
    },
    addSelectedForUpgrade(type, id) {
      if (type === "Gold") {
        this.selectedForGoldUpgrade.push(id);
      } else {
        this.selectedForRoyalUpgrade.push(id);
      }
    },
    anotherUpgrade() {
      this.$router.go();
    },
    async upgrade(ids, type) {
      const method = type === "gold" ? "goldUpgrade" : "royalUpgrade";
      const successMsg =
        type === "gold"
          ? "Congrats you've upgraded from 3 central passes to a gold pass."
          : "Congrats you've upgraded from 10 gold passes to a royal pass.";
      try {
        // Put Trans Into Progress
        this.inProgress = true;
        // Check for ApprovalForAll
        const approvalOptions = {
          chain: this.network,
          address: this.collectingMetaAddress,
          function_name: "isApprovedForAll",
          abi: this.collectingMetaAddressAbi,
          params: {
            owner: Moralis.User.current().get("ethAddress"),
            operator: this.upgradeContractAddress,
          },
        };
        let approval = await Moralis.Web3API.native.runContractFunction(
          approvalOptions
        );

        if (!approval) {
          const approvalOptions = {
            chain: this.network,
            contractAddress: this.collectingMetaAddress,
            functionName: "setApprovalForAll",
            abi: this.collectingMetaAddressAbi,
            params: {
              operator: this.upgradeContractAddress,
              approved: true,
            },
          };
          const approved = await Moralis.executeFunction(approvalOptions);
          await approved.wait();
        }

        // Get a signature
        let res = await Moralis.Cloud.run("cmUpgradeSign", { token_ids: ids });
        console.log(res.sig);
        // Make call to contract
        const upgradeOptions = {
          chain: this.network,
          contractAddress: this.upgradeContractAddress,
          functionName: method,
          abi: this.upgradeContractAddressAbi,
          params: {
            tokenIds: ids,
            sig: res.sig,
          },
          msgValue: Moralis.Units.ETH("0"),
        };
        let upgrade = await Moralis.executeFunction(upgradeOptions);
        await upgrade.wait();
        this.details.transactionHash = upgrade.hash;
        this.details.etherscanLink = "https://etherscan.io/tx/" + upgrade.hash;
        this.details.status = "Success";
        this.details.message = successMsg;
      } catch (e) {
        this.details.transactionHash = "";
        this.details.etherscanLink = "";
        this.details.status = "Error";
        this.details.message = e.message;
      }
      // Return etherscan trans or error
    },
  },
  async mounted() {
    const self = this;
    try {
      if (Moralis.User.current()) {
        console.log(Moralis.User.current().get("ethAddress"));
        let lastCursor = null;
        for (let f = 0; f < 10; f++) {
          const options = {
            chain: self.network,
            address: Moralis.User.current().get("ethAddress"),
            token_addresses: [self.collectingMetaAddress],
            limit: 100,
            cursor: lastCursor,
          };
          console.log(options);
          let data = await Moralis.Web3API.account.getNFTs(options);
          console.log(data);
          lastCursor = data.cursor;

          for (let i = 0; i < data.result.length; i++) {
            fetch(
              `https://api.collectingmeta.com/api/${data.result[i].token_id}`
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                if (data.attributes[0].value === "Central") {
                  self.centralNfts.push(data);
                }
                if (data.attributes[0].value === "Gold") {
                  self.goldNfts.push(data);
                }
              })
              .catch(function (err) {
                console.log("Fetch Error :-S", err);
              });
          }

          if (data.result.length < 100) {
            break;
          }
          // this.centralNfts = centralArray;
          // this.goldNfts = goldArray;
        }
        const indexOptions = {
          chain: this.network,
          address: this.upgradeContractAddress,
          function_name: "goldIndex",
          abi: this.upgradeContractAddressAbi,
          params: {},
        };
        this.completedGoldUpgrades = await Moralis.Web3API.native.runContractFunction(
          indexOptions
        );
        const royalIndexOptions = {
          chain: this.network,
          address: this.upgradeContractAddress,
          function_name: "royalIndex",
          abi: this.upgradeContractAddressAbi,
          params: {},
        };
        this.completedRoyalUpgrades = await Moralis.Web3API.native.runContractFunction(
          royalIndexOptions
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
